<template>
  <v-card id="user-create-card">
    <v-card-title class="py-3 px-4 border-bottom">
      <span>
        Yeni istifadəçi
      </span>
    </v-card-title>
    <v-card-text>
      <v-form
        id="createUserForm"
        ref="form"
        v-model="formValidation"
        lazy-validation
        @submit.prevent="handleFormSubmit"
      >
        <v-row>
          <v-col cols="6" class="mb-3">
            <v-combobox
              v-model="selectedEmployee"
              :rules="formRules.selectedEmployee"
              @update:search-input="getEmployeeList"
              required
              dense
              label="Əməkdaş*"
              placeholder="Ən azı 3 simvol daxil edin"
              filled
              hide-details="auto"
              :items="employeeList"
              item-value="id"
              item-text="firstName"
            >
              <template v-slot:no-data>
                <p class="px-2 subtitle-2 mb-0">Əməkdaş mövcud deyil</p>
              </template>
              <template v-slot:selection="{ attrs, item, selected }">
                <span
                  :input-value="selected"
                  v-if="item === Object(item)"
                  v-bind="attrs"
                  >{{
                    `${item.firstName} ${item.lastName} ${item.middleName}`
                  }}</span
                >
              </template>
              <template v-slot:item="{ item }">
                {{ `${item.firstName} ${item.lastName} ${item.middleName}` }}
              </template>
            </v-combobox>
          </v-col>
          <v-col cols="6" class="mb-3">
            <v-text-field
              type="text"
              label="İstifadəçi adı*"
              filled
              dense
              v-model="formData.userName"
              :error-messages="errorMessages"
              required
              maxlength="50"
              minlength="5"
              :error="inputHasError"
              @blur="checkUserNameValidity"
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="mb-3">
            <v-select
              label="Rollar*"
              filled
              dense
              multiple
              chips
              deletable-chips
              clearable
              required
              item-text="value"
              item-value="id"
              v-model="formData.roleIds"
              :rules="formRules.roleIds"
              :items="roles"
              hide-details="auto"
            >
              <template v-slot:selection="{ item, index }">
                <v-chip
                  label
                  close
                  small
                  color="gray lighten-4"
                  class="gray--text mx-0"
                  v-if="index === 0"
                  @click:close="remove(index)"
                >
                  <span :style="{ color: 'rgba(0, 0, 0, 0.87)' }">
                    {{ item.value }}
                  </span>
                </v-chip>
                <span v-if="index === 3" class="grey--text caption ml-2">
                  (+{{ formData.roleIds.length - 3 }} əlavə)
                </span>
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" class="mb-3">
            <v-text-field
              label="Şifrə*"
              filled
              required
              maxlength="20"
              minlength="6"
              v-model="formData.password"
              :rules="formRules.password"
              :type="isPasswordVisible ? 'text' : 'password'"
              :append-icon="getPasswordInputIcon(isPasswordVisible)"
              @click:append="togglePasswordVisibility"
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              label="Şifrənin təkrarı*"
              filled
              required
              maxlength="20"
              minlength="6"
              v-model="formData.passwordRepeat"
              :rules="formRules.passwordRepeat"
              :type="isPasswordRepeatVisible ? 'text' : 'password'"
              :append-icon="getPasswordInputIcon(isPasswordRepeatVisible)"
              @click:append="togglePasswordRepeatVisibility"
              hide-details="auto"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions class="py-3 px-4 d-flex justify-end border-top">
      <v-btn color="gray lighten-3" depressed @click="toggleDialog">
        Bağla
      </v-btn>
      <v-btn
        :loading="isSubmittingForm"
        type="submit"
        form="createUserForm"
        color="success"
        depressed
      >
        Yarat
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import API from "@/services";

export default {
  props: {
    toggleDialog: {
      type: Function,
      required: true,
    },
    isSubmittingForm: {
      type: Boolean,
      required: true,
    },
    selectedUser: {
      type: Object,
      required: false,
      default: () => null,
    },
    roles: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isPasswordVisible: false,
      isPasswordRepeatVisible: false,
      formValidation: false,
      formData: {
        userName: "",
        firstName: "",
        lastName: "",
        middleName: "",
        emailAddress: "",
        phoneNumber: "",
        positionId: "",
        password: "",
        passwordRepeat: "",
        roleIds: [],
        employeeId: null,
      },
      employeeList: [],
      selectedEmployee: null,
      formRules: {
        firstName: [(v) => !!v || "İstifadəçinın adını daxil edin"],
        lastName: [(v) => !!v || "İstifadəçinin soy adını daxil edin"],
        positionId: [(v) => !!v || "Vəzifə seçin"],
        emailAddress: [
          (v) => /.+@.+/.test(v) || "E-poçt ünvanı düzgün formatda deyil",
        ],
        phoneNumber: [
          (v) => !!v || "Əlaqə nömrəsini daxil edin",
          (v) =>
            (!!v && v.length == 9) ||
            "Əlaqə nömrəsi 9 rəqəmdən ibarət olmalıdır",
          (v) =>
            /^(50|51|55|70|77|99)([1-9]{1})([0-9]{6})$/.test(v) ||
            "Əlaqə nömrəsi 50,51,55,70,77,99 ilə başlamalıdır",
        ],
        password: [
          (v) => !!v || "Şifrəni daxil edin",
          (v) =>
            (!!v && v.length >= 6 && v.length <= 20) ||
            "Şifrənin uzunlugu ən az 6 və ən çox 20 simvol ola bilər",
          (v) =>
            /^(?!\.)(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[.#$^+=!*()@%&]).{6,20}$/.test(
              v
            ) ||
            "Şifrədə ən az bir kiçik və bir böyük hərf, bir rəqəm və ən az bir simvol olmalıdır (.#$^+=!*()@%&)",
        ],
        passwordRepeat: [
          (v) => !!v || "Şifrəni təkrar daxil edin",
          (v) =>
            (!!v && v.length >= 6 && v.length <= 20) ||
            "Şifrənin uzunlugu ən az 6 və ən çox 20 simvol ola bilər",
          (v) =>
            /^(?!\.)(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[.#$^+=!*()@%&]).{6,20}$/.test(
              v
            ) ||
            "Şifrədə ən az bir kiçik və bir böyük hərf, bir rəqəm və ən az bir simvol olmalıdır (.#$^+=!*()@%&)",
          (v) =>
            v === this.formData.password || "Şifrələr bir-birinə uyğun deyil",
        ],
        roleIds: [(v) => v.length > 0 || "Ən azı bir rol seçilməlidir"],
      },
      inputHasError: false,
      errorMessages: [],
    };
  },
  watch: {
    selectedUser: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.formData = { ...newVal };
        } else {
          this.resetFormData();
        }
      },
    },
    selectedEmployee: {
      handler() {
        this.formData.firstName = this.selectedEmployee.firstName;
        this.formData.lastName = this.selectedEmployee.lastName;
        this.formData.middleName = this.selectedEmployee.middleName;
        this.formData.emailAddress = this.selectedEmployee.emailAddress;
        this.formData.phoneNumber = this.selectedEmployee.phoneNumber;
        this.formData.positionId = this.selectedEmployee.positionId;
        this.formData.employeeId = this.selectedEmployee.id;
      },
    },
  },
  methods: {
    async getEmployeeList(text) {
      if (text && text.length > 2) {
        this.employeeList = await API.fetchNonSelectedUsers(text);
      }
    },
    togglePasswordVisibility() {
      this.isPasswordVisible = !this.isPasswordVisible;
    },
    togglePasswordRepeatVisibility() {
      this.isPasswordRepeatVisible = !this.isPasswordRepeatVisible;
    },
    getPasswordInputIcon(isVisible) {
      return isVisible ? "mdi-eye" : "mdi-eye-off";
    },
    handleFormSubmit() {
      const isFormValid = this.$refs.form.validate();
      if (isFormValid) {
        this.$emit("formSubmit", this.formData);
        console.log('d',this.formData)
      }
    },
    resetFormData() {
      this.formData = {
        userName: "",
        firstName: "",
        lastName: "",
        middleName: "",
        emailAddress: "",
        phoneNumber: "",
        positionId: "",
        password: "",
        passwordRepeat: "",
        roleIds: [],
      };
      this.$refs.form && this.$refs.form.resetValidation();
    },
    remove(index) {
      if (index >= 0) this.formData.roleIds.splice(index, 1);
    },
    async checkUserNameValidity() {
      const { userName } = this.formData;

      if (!userName) {
        this.inputHasError = true;
        this.errorMessages = ["İstifadəçi adını daxil edin"];
        return;
      }

      if (userName.length < 5 || userName.length > 50) {
        this.inputHasError = true;
        this.errorMessages = [
          "İstifadəçi adının uzunlugu ən az 5 və ən çox 50 simvol ola bilər",
        ];
        return;
      }

      if (!/^[a-z]+(\.[a-z]+)*(\d)*$/.test(userName)) {
        this.inputHasError = true;
        this.errorMessages = [
          "İstifadəçi adı kiçik hərflərdən, nöqtələrdən və rəqəmlərdən ibarət ola bilər",
        ];
        return;
      }

      API.checkUsernameUniqueness(userName).then((response) => {
        if (!response) {
          this.inputHasError = true;
          this.errorMessages = ["İstifadəçi adı mövcuddur"];
          return;
        }

        this.inputHasError = false;
        this.errorMessages = [];
      });
    },
  },
};
</script>
