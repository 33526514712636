<template>
  <div class="border-all rounded">
    <div class="pt-4 px-4">
      <v-row class="my-0">
        <v-col cols="3" class="mb-3">
          <v-text-field
            type="text"
            label="Ad"
            filled
            dense
            v-model="filters.firstName"
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="3" class="mb-3">
          <v-text-field
            type="text"
            label="Soyad"
            filled
            dense
            v-model="filters.lastName"
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="3" class="mb-3">
          <v-text-field
            type="text"
            label="İstifadəçi adı"
            filled
            dense
            v-model="filters.userName"
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="3" class="mb-3">
          <v-text-field
            type="email"
            label="Elektron poçt"
            filled
            dense
            v-model="filters.emailAddress"
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="3" class="mb-3">
          <v-autocomplete
            label="Vəzifə"
            filled
            dense
            clearable
            hide-details
            item-text="value"
            item-value="id"
            v-model="filters.positionId"
            :items="positions"
          >
          </v-autocomplete>
        </v-col>
        <v-col cols="3" class="mb-3">
          <v-select
            label="Rol"
            filled
            dense
            clearable
            hide-details
            item-text="value"
            item-value="id"
            v-model="filters.roleId"
            :items="roles"
          >
          </v-select>
        </v-col>
        <v-col cols="3" class="mb-3">
          <v-text-field
            type="number"
            label="Əlaqə nömrəsi"
            filled
            dense
            v-model="filters.phoneNumber"
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
    </div>
    <div class="border-top py-3 px-4">
      <div class="d-flex justify-end">
        <v-btn @click="clear" class="mr-3" color="gray lighten-3" depressed>
          Təmizlə
        </v-btn>
        <v-btn @click="search" color="primary" depressed>
          Axtar
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    roles: {
      type: Array,
      required: true
    },
    positions: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      filters: {
        firstName: '',
        lastName: '',
        userName: '',
        emailAddress: '',
        positionId: '',
        roleId: '',
        phoneNumber: ''
      }
    }
  },
  methods: {
    search() {
      this.$emit('filtersChanged', this.filters)
    },
    clear() {
      this.$emit('filtersChanged', {})
      this.filters = {
        firstName: '',
        lastName: '',
        userName: '',
        emailAddress: '',
        positionId: '',
        roleId: '',
        phoneNumber: ''
      }
    }
  }
}
</script>
