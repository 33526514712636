<template>
  <v-container fluid class="down-top-padding">
    <div id="usersPage" class="page">
      <div class="page__header">
        <Toolbar
          :showFiltersPanel="showFiltersPanel"
          @showFiltersPanelChanged="handleShowFiltersPanelChanged"
          @toggleDialog="toggleDialog"
        />
      </div>
      <div class="page__body pa-4">
        <div class="mb-4" v-show="showFiltersPanel">
          <v-row class="my-0">
            <v-col md="12">
              <Filters
                :roles="roles"
                :positions="positions"
                @filtersChanged="handleFiltersChanged"
              />
            </v-col>
          </v-row>
        </div>

        <List
          :page="page"
          :items="items"
          :totalCount="totalCount"
          :isFetchingItems="isFetchingItems"
          :itemsPerPage="itemsPerPage"
          @editItem="handleEditItem"
          @changeStatus="handleChangeStatus"
          @changePassword="toggleChangePasswordDialog"
          @optionsChanged="handleOptionsChanged"
        />
      </div>

      <v-dialog v-model="dialog" persistent scrollable max-width="1000px">
        <EditForm
          v-if="selectedUser && selectedUser.id && selectedUser.id > 0"
          @formSubmit="handleFormSubmit"
          :roles="roles"
          :toggleDialog="toggleDialog"
          :isSubmittingForm="isSubmittingForm"
          :selectedUser="selectedUser"
        />
        <CreateForm
          v-else-if="dialog"
          @formSubmit="handleFormSubmit"
          :roles="roles"
          :toggleDialog="toggleDialog"
          :isSubmittingForm="isSubmittingForm"
          :selectedUser="selectedUser"
        />
      </v-dialog>
      <v-dialog
        v-model="changePasswordDialog"
        persistent
        scrollable
        max-width="1000px"
      >
        <ChangePasswordForm
          @formSubmit="handleUpdatePassword"
          :toggleDialog="toggleChangePasswordDialog"
          :isSubmittingForm="isUpdatingPassword"
        />
      </v-dialog>
    </div>
  </v-container>
</template>

<script>
import API from "@/services";
import List from "./components/List/index.vue";
import CreateForm from "./components/Form/CreateForm/index.vue";
import EditForm from "./components/Form/EditForm/index.vue";
import ChangePasswordForm from "./components/ChangePasswordForm/index.vue";
import Toolbar from "./components/Toolbar/index.vue";
import Filters from "./components/Toolbar/components/Filters.vue";
import bus from "../../../event-bus";

export default {
  components: {
    List,
    CreateForm,
    EditForm,
    ChangePasswordForm,
    Toolbar,
    Filters,
  },
  data() {
    return {
      dialog: false,
      changePasswordDialog: false,
      confirmationDialog: false,
      selectedUser: null,
      page: 1,
      isFetchingItems: false,
      isSubmittingForm: false,
      isUpdatingPassword: false,
      isDeletingRole: false,
      totalCount: 0,
      itemsPerPage: 10,
      items: [],
      roles: [],
      positions: [],
      filters: {},
      sortedBy: null,
      sortedDesc: null,
      showFiltersPanel: false,
    };
  },
  async created() {
    this.roles = await API.fetchAllRoles();
    this.positions = await API.fetchAllPositions();
    this.fetchUsers();
  },
  methods: {
    async fetchUsers() {
      this.isFetchingItems = true;

      await API.fetchUsers({
        limit: this.itemsPerPage,
        offset: (this.page - 1) * this.itemsPerPage,
        sortedBy: this.sortedBy,
        sortedDesc: this.sortedDesc,
        ...this.filters,
      })
        .then((response) => {
          this.items = response.items;
          this.totalCount = response.totalCount;
        })
        .catch(() => {
          bus.$emit("notificationMessage", {
            color: "error",
            text: "İstifadəçilərin siyahısını əldə etmək mümkün olmadı.",
          });
        })
        .finally(() => {
          this.isFetchingItems = false;
        });
    },
    handleFormSubmit(formData) {
      formData.id ? this.updateUser(formData) : this.insertUser(formData);
    },
    insertUser(formData) {
      this.isSubmittingForm = true;
      API.insertUser(formData)
        .then(() => {
          this.toggleDialog();
          this.fetchUsers();
        })
        .catch(() => {
          bus.$emit("notificationMessage", {
            color: "error",
            text: "İstifadəçini əlavə etmək mümkün olmadı.",
          });
        })
        .finally(() => {
          this.isSubmittingForm = false;
        });
    },
    updateUser(formData) {
      this.isSubmittingForm = true;
      API.updateUser(formData)
        .then(() => {
          this.toggleDialog();
          this.fetchUsers();
        })
        .catch(() => {
          bus.$emit("notificationMessage", {
            color: "error",
            text:
              "İstifadəçi məlumatları üzərində dəyişiklik etmək mümkün olmadı.",
          });
        })
        .finally(() => {
          this.isSubmittingForm = false;
        });
    },
    handleUpdatePassword(formData) {
      this.isUpdatingPassword = true;
      API.updatePassword(this.selectedUser.id, formData)
        .then(() => {
          this.toggleChangePasswordDialog(null);
        })
        .catch(() => {
          bus.$emit("notificationMessage", {
            color: "error",
            text: "Şifrəni yeniləmək mümkün olmadı.",
          });
        })
        .finally(() => {
          this.isUpdatingPassword = false;
        });
    },
    handleChangeStatus(item) {      
      API.changeUserStatus(item.id)
        .then(() => {
          this.fetchUsers();
        })
        .catch(() => {
          bus.$emit("notificationMessage", {
            color: "error",
            text: "İstifadəçinin statusunu dəyişmək mümkün olmadı.",
          });
        });
    },
    async handleEditItem(item) {
      this.isFetchingItems = true;
      API.fetchUserById(item.id)
        .then((response) => {
          this.selectedUser = response;
          this.dialog = true;
        })
        .catch(() => {
          bus.$emit("notificationMessage", {
            color: "error",
            text: "Məlumatı əldə etmək mümkün olmadı.",
          });
        })
        .finally(() => {
          this.isFetchingItems = false;
        });
    },
    handleFiltersChanged(filters) {
      if (JSON.stringify(filters) !== JSON.stringify(this.filters)) {
        this.page = 1;
        this.filters = { ...filters };
        this.fetchUsers();
      }
    },
    toggleDialog() {
      this.dialog = !this.dialog;
      if (!this.dialog) {
        this.selectedUser = null;
      }
    },
    toggleChangePasswordDialog(item) {
      this.selectedUser = item;
      this.changePasswordDialog = !this.changePasswordDialog;
    },
    handleOptionsChanged(item) {
      if (
        this.sortedBy !== item.sortedBy ||
        this.sortedDesc !== item.sortedDesc ||
        this.page !== item.page ||
        this.itemsPerPage !== item.itemsPerPage
      ) {
        this.page = item.page;
        this.itemsPerPage = item.itemsPerPage;
        this.sortedBy = item.sortedBy;
        this.sortedDesc = item.sortedDesc;
        this.fetchUsers();
      }
    },
    handleShowFiltersPanelChanged(item) {
      this.showFiltersPanel = item;
    },
  },
};
</script>
