<template>
  <v-card id="user-edit-card">
    <v-card-title class="py-3 px-4 border-bottom">
      <span>
        İstifadəçinin redaktəsi
      </span>
    </v-card-title>
    <v-card-text>
      <v-form
        id="updateUserForm"
        ref="form"
        v-model="formValidation"
        lazy-validation
        @submit.prevent="handleFormSubmit"
      >
        <v-row>
          <v-col cols="6" class="mb-3">
            <v-text-field
              hide-details="auto"
              disabled
              type="text"
              label="Əməkdaş"
              filled
              dense
              :value="
                `${formData.firstName} ${formData.lastName} ${formData.middleName}`
              "
            ></v-text-field>
          </v-col>
          <v-col cols="6" class="mb-3">
            <v-text-field
              hide-details="auto"
              type="text"
              label="İstifadəçi adı"
              filled
              dense
              v-model="formData.userName"
              :rules="formRules.userName"
              required
              maxlength="50"
              minlength="5"
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="mb-3">
            <v-select
              label="Rollar"
              filled
              dense
              multiple
              chips
              deletable-chips
              clearable
              required
              item-text="value"
              item-value="id"
              v-model="formData.roleIds"
              :rules="formRules.roleIds"
              :items="roles"
              hide-details="auto"
            >
              <template v-slot:selection="{ item, index }">
                <v-chip
                  label
                  close
                  small
                  color="gray lighten-4"
                  class="gray--text mx-0"
                  v-if="index === 0"
                  @click:close="remove(index)"
                >
                  <span :style="{ color: 'rgba(0, 0, 0, 0.87)' }">
                    {{ item.value }}
                  </span>
                </v-chip>
                <span v-if="index === 3" class="grey--text caption ml-2">
                  (+{{ formData.roleIds.length - 3 }} əlavə)
                </span>
              </template>
            </v-select>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions class="py-3 px-4 d-flex justify-end border-top">
      <v-btn color="gray lighten-3" depressed @click="toggleDialog">
        Bağla
      </v-btn>
      <v-btn
        :loading="isSubmittingForm"
        type="submit"
        form="updateUserForm"
        color="success"
        depressed
      >
        Yadda saxla
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import API from "@/services";

export default {
  props: {
    toggleDialog: {
      type: Function,
      required: true,
    },
    isSubmittingForm: {
      type: Boolean,
      required: true,
    },
    selectedUser: {
      type: Object,
      required: false,
      default: () => null,
    },
    roles: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      formValidation: false,
      formData: {
        id: "",
        userName: "",
        firstName: "",
        lastName: "",
        middleName: "",
        emailAddress: "",
        phoneNumber: "",
        positionId: "",
        roleIds: [],
      },
      formRules: {
        firstName: [(v) => !!v || "İstifadəçinın adını daxil edin"],
        lastName: [(v) => !!v || "İstifadəçinin soy adını daxil edin"],
        positionId: [(v) => !!v || "Vəzifə seçin"],
        emailAddress: [
          (v) => /.+@.+/.test(v) || "E-poçt ünvanı düzgün formatda deyil",
        ],
        phoneNumber: [
          (v) => !!v || "Əlaqə nömrəsini daxil edin",
          (v) =>
            (!!v && v.length == 9) ||
            "Əlaqə nömrəsi 9 rəqəmdən ibarət olmalıdır",
          (v) =>
            /^(50|51|55|70|77|99)([1-9]{1})([0-9]{6})$/.test(v) ||
            "Əlaqə nömrəsi 50,51,55,70,77,99 ilə başlamalıdır",
        ],
        roleIds: [(v) => v.length > 0 || "Ən azı bir rol seçilməlidir"],
      },
      inputHasError: false,
      errorMessages: [],
    };
  },
  watch: {
    selectedUser: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.formData = { ...newVal };
        } else {
          this.resetFormData();
        }
      },
    },
  },
  methods: {
    handleFormSubmit() {
      const isFormValid = this.$refs.form.validate();
      if (isFormValid) {
        this.$emit("formSubmit", this.formData);
      }
    },
    resetFormData() {
      this.formData = {
        userName: "",
        firstName: "",
        lastName: "",
        middleName: "",
        emailAddress: "",
        phoneNumber: "",
        positionId: "",
        roleIds: [],
      };
      this.$refs.form && this.$refs.form.resetValidation();
    },
    remove(index) {
      if (index >= 0) this.formData.roleIds.splice(index, 1);
    },
    async checkUserNameValidity() {
      const { userName, id } = this.formData;

      if (!userName) {
        this.inputHasError = true;
        this.errorMessages = ["İstifadəçi adını daxil edin"];
        return;
      }

      if (userName.length < 5 || userName.length > 50) {
        this.inputHasError = true;
        this.errorMessages = [
          "İstifadəçi adının uzunlugu ən az 5 və ən çox 50 simvol ola bilər",
        ];
        return;
      }

      if (!/^[a-z]+(\.[a-z]+)*(\d)*$/.test(userName)) {
        this.inputHasError = true;
        this.errorMessages = [
          "İstifadəçi adı kiçik hərflərdən, nöqtələrdən və rəqəmlərdən ibarət ola bilər",
        ];
        return;
      }

      API.checkUsernameUniqueness(userName, id).then((response) => {
        if (!response) {
          this.inputHasError = true;
          this.errorMessages = ["İstifadəçi adı mövcuddur"];
          return;
        }

        this.inputHasError = false;
        this.errorMessages = [];
      });
    },
  },
};
</script>
