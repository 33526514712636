<template>
  <v-data-table
    :page="page"
    :items-per-page="itemsPerPage"
    :items="items"
    :headers="headers"
    :loading="isFetchingItems"
    :server-items-length="totalCount"
    :item-class="getItemClass"
    :options.sync="options"
    :footer-props="{ 'items-per-page-options': [10, 25, 50, 100] }"
    class="user-list"
  >
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon class="mr-2" @click="editItem(item)">
        mdi-pencil
      </v-icon>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-horizontal </v-icon>
          </v-btn>
        </template>

        <v-list dense class="pt-1 pb-1">
          <v-list-item
            @click="changeStatus(item)"
            class="gray--text text--darken-3"
          >
            <v-list-item-title class="text-left body-2 font-weight-regular">
              {{ item.isActive ? "Deaktivləşdir" : "Aktivləşdir" }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            @click="changePassword(item)"
            class="gray--text text--darken-3"
          >
            <v-list-item-title class="body-2 font-weight-regular">
              Şifrəni yenilə
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: {
    isFetchingItems: {
      type: Boolean,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    },
    itemsPerPage: {
      type: Number,
      required: true,
    },
    totalCount: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      headers: [
        { text: "A.S.A", value: "fullName", sortable: true },
        { text: "İstifadəçi adı", value: "userName", sortable: true },
        { text: "Elektron poçt", value: "emailAddress", sortable: false },
        { text: "Vəzifə", value: "position", sortable: false },
        { text: "Əlaqə nömrəsi", value: "phoneNumber", sortable: false },
        { text: "", value: "actions", sortable: false, align: "end" },
      ],
      options: {},
    };
  },
  watch: {
    options: {
      handler(value) {
        const { page, itemsPerPage, sortBy, sortDesc } = value;
        if (
          (sortBy.length > 0 && sortDesc.length > 0) ||
          page > 0 ||
          itemsPerPage >= 10
        ) {
          this.$emit("optionsChanged", {
            page: page,
            itemsPerPage: itemsPerPage,
            sortedBy: sortBy[0],
            sortedDesc: sortDesc[0],
          });
        }
      },
      deep: true,
    },
  },
  methods: {
    getItemClass(item) {
      if (!item.isActive) {
        return "red accent-1";
      }
      return "";
    },
    editItem(item) {
      this.$emit("editItem", item);
    },
    changeStatus(item) {
      this.$emit("changeStatus", item);
    },
    changePassword(item) {
      this.$emit("changePassword", item);
    },
  },
};
</script>
